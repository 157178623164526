import { gnbId } from '../../constants';

export default function gnbPcGenerater ({ sectionId = '', sectionData }) {
  const gnbFooterType = sectionData.gnbHeaderType === 'main' ? 'normal' : sectionData.gnbHeaderType;

  //console.log('gnbHeaderType :>> ', sectionData.gnbHeaderType);
  if (!window || typeof window.SbsGnbApp !== 'function') return false;

  return window.SbsGnbApp('', {
    env: process.env.NEXT_PUBLIC_IS_DEV === 'Y' ? 'dev' : 'real', // TODO: 추후 이부분은 환경변수로 관리 할 것('local', 'dev', 'real')
    platform: 'desktop',
    header: {
      id: gnbId.headerId,
      type: sectionData.gnbHeaderType,
      color: 'black',
      section: sectionData.gnbSection || ''
    },
    footer: {
      id: gnbId.footerId,
      type: gnbFooterType,
      color: 'black'
    },
    use: {
      async: true,
      logging: true
    }
  }).then(function (app) {
    if (sectionData.gnbHeaderType === 'simple') {
      app.event.headerCloseClicked = function (event) {
        window.history.back();
      };
    }

    return app;
  }).catch(function (error) {
    console.error(error);
  });
}
